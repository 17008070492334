const initState = {
    authError: null,
    playfabID: ""
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SET_PF_ID':
            return {
                ...state,
                playfabID: action.id
            }
        default:
            return state;
    }

}

export default authReducer