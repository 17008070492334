import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/functions'

const firebaseConfig = {

  apiKey: "AIzaSyAxyptg221S5WmvxdxpE3Y-ak9KQ5T6sD0",
  authDomain: "sar-drops.firebaseapp.com",
  projectId: "sar-drops",
  storageBucket: "sar-drops.appspot.com",
  messagingSenderId: "281142365244",
  appId: "1:281142365244:web:7ff51b5e7f737781bbc739",
  measurementId: "G-YXT7VK4XMG"

};


export var cloudFunctionsURL = "https://us-central1-sar-drops.cloudfunctions.net"

  firebase.initializeApp(firebaseConfig);
  firebase.functions()

  export default firebase;