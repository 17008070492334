import React, { Component } from 'react'
import { connect} from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import {compose} from 'redux'
import {Redirect} from 'react-router-dom'
import { cloudFunctionsURL } from '../config/fbConfig'
import {  getFirestore } from 'redux-firestore'

var GETAUTHTWITCH = "https://id.twitch.tv/oauth2/authorize?response_type=code&client_id=6vr7jmmynp2vpeoyv1jvwiamiw57or&redirect_uri=https://drops.animalroyale.com/&scope=user_read"

class Home extends Component {

    constructor(props) {
        super(props);
        // Crea una referencia para guardar el elemento textInput del DOM
        this.emailField = React.createRef();    
        this.passField = React.createRef();    
      }
    state = {
        email: "",
        password: "",
        playfabID: "",
        twitchID: "",
        authError: "",
        status: "waiting",
        message: "Linking accounts...",
        pfStatus: 0
    };

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    //Mover esto al back
    playfabLogin= (e) => {
        e.preventDefault();

        if(this.emailField.current.value != this.state.email)
            this.state.email = this.emailField.current.value
        if(this.passField.current.value != this.state.password)
            this.state.password = this.passField.current.value

        console.log("Log a Playfab")
        console.log(this.state.email,this.state.password)
        const requestOptions = {
            method: 'POST',
            headers: { Accept: 'application/json','Content-Type': 'application/json' },
            body: JSON.stringify({  
                Email: this.state.email,
                Password: this.state.password,
                TitleId: "D36D" })
        };

        this.state.authError = ""

        fetch('https://D36D.playfabapi.com/Client/LoginWithEmailAddress', requestOptions)
            .then(response => response.json())
            .then(data => 
                {
                    console.log(data)
                    if(data.status == "OK")
                    {
                        this.checkPFA(data.data.PlayFabId);
                    }
                    else
                    {
                        var error = "Error"
                        if(data.errorMessage != null)
                            error = data.errorMessage
                        this.setState({authError:error});
                    }
                })
            .catch(error => console.log(error))
    }

    async checkPFA(pfid)
    {
        localStorage.setItem('playfabID', pfid);
                        var pfa = 0;

                        console.log("Checking account");
                        const doc = await getFirestore().collection("Playfab").doc(pfid).get();
                        if (doc.exists) {
                            pfa = 1;    
                            console.log("Account Exist");
                        } 
                        else
                        {   
                            pfa = 2;
                            console.log("Account dosnt Exist");
                        }
                        this.setState({playfabID:pfid,pfStatus:pfa});
    }

    async componentDidMount() {
      
        var localPlayfabID = localStorage.getItem('playfabID');
        if(localPlayfabID == null) 
            localPlayfabID = ""

        var parsed_qs = []
        var query_string = this.props.location.search

        //NO SE PORQUE EMPIEZA EN # Y NO EN ?
        //CHECAR
        if(query_string[0] == "#" || query_string[0] == "?")
            query_string = query_string.substring(1);
        if(query_string[0] != "")
            parsed_qs = this.parse_query_string(query_string);
        var accessToken = ""
        if(parsed_qs.code != undefined)
            accessToken = parsed_qs.code

        
            console.log(localPlayfabID)
            console.log(accessToken)
        
        

        var pfa = 0;

        if(localPlayfabID != ""){
            console.log("Checking account");
            const doc = await getFirestore().collection("Playfab").doc(localPlayfabID).get();
            if (doc.exists) {
                pfa = 1;
                
            console.log("Account Exist");
            } 
            else
            {   
                pfa = 2;
                console.log("Account dosnt Exist");
            }
        }

        if(localPlayfabID != "" && accessToken != "")
        {
            this.connectAccount(localPlayfabID,accessToken);
        }

        this.setState({
            playfabID:localPlayfabID,
            twitchID: accessToken,
            pfStatus: pfa
        })
    }

    componentDidUpdate(){

    }

    connectAccount(Playfab,OAuth)
    {
        console.log("IDs:" +Playfab + " " + OAuth)
        const requestOptions = {
            method: 'POST',
            headers: { Accept: 'application/json','Content-Type': 'application/json' },
            body: JSON.stringify({  
                Playfab: Playfab,
                OAuth: OAuth 
            })
        };

        fetch('https://us-central1-sar-drops.cloudfunctions.net/connectAccount ', requestOptions)
            .then(response => response.json())
            .then(data => 
                {
                    console.log(data) 
                    this.setState({status:data.status,message:data.message})                  
                })
            .catch(error => {
                console.log(error)
                this.setState({status:"bad",message:error})            
            })
        
    }

    parse_query_string(query) {
        var vars = query.split("&");
        var query_string = {};
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          var key = decodeURIComponent(pair[0]);
          var value = decodeURIComponent(pair[1]);
          // If first entry with this name
          if (typeof query_string[key] === "undefined") {
            query_string[key] = decodeURIComponent(value);
            // If second entry with this name
          } else if (typeof query_string[key] === "string") {
            var arr = [query_string[key], decodeURIComponent(value)];
            query_string[key] = arr;
            // If third or later entry with this name
          } else {
            query_string[key].push(decodeURIComponent(value));
          }
        }
        return query_string;
      }
      
    playfabLogout()
    {
        this.setState({playfabID:""});
    }

    render(){
        
        const { playfabID,twitchID,authError,status,message,pfStatus } = this.state;
        var state = 3

        var linkingMessage = "#deb746" 
       // if(status == "waiting") // mensaje amarillo
        if(status == "bad") // mensaje rojo
            linkingMessage = "#db636c"
        if(status == "ok") // color verde
            linkingMessage = "#43ad76"

        /*
            3 casos ( pantallas ):
                1.- Usuario no esta identificado en playfab. Esta pantalla pedira su login a playfab.
                2.- Usuario esta identificado en playfab. Esta pantalla tendra dos posibles muestras:
                    2a.- La cuenta no esta conectada con una cuenta de twitch. Boton para pedirle al usuario conectar las cuentas
                    2b.- La cuenta esta conectada a twitch. Decirle al usuario que su cuenta de playfab ya esta conectada a una cuenta de twitch
                3.- Cuando el usuario da click a conectar cuentas en 2a. Esta pantalla le dita al usuario que su conexion fue exitosa

            2b y 3 pueden ser la misma pantalla. Una que solo informa que su cuenta ya esta conectada.
        */
        if(playfabID == ""  ){
        return (
            <div style={{width: "100%", backgroundColor: 'transparent', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>   
                <div style = {{display: 'flex', flexDirection: 'column' , justifyContent: 'center', alignItems: 'center', backgroundColor: 'transprent', width: '70%'}}>
                    <img style={{ backgroundColor: 'transprent', marginTop:10,  height: '40%', width: '40%', maxHeight:300, maxWidth: 510}} src={require("./img/sar-logo.png")}/>
                </div>
                <div  style={{width: "90%",  display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
                    <a style={{textAlign: 'center', fontSize: 28, textJustify: 'center', marginBottom: 0, color: "white", fontWeight: '500', fontFamily: 'Rubik'}}>TWITCH DROPS</a>
                </div>
                <div  style={{width: "90%",  display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
                    <a style={{textAlign: 'center', fontSize: 20, textJustify: 'center', marginBottom: 20, color: "white", fontWeight: '300', fontFamily: 'Rubik'}}>Watch streams to earn in-game rewards</a>
                </div> 
                <div  style={{width: "80%", maxWidth: 800, display: 'flex', flexDirection: "column", marginTop: 0, backgroundColor: '#43ad76', borderRadius:10, justifyContent: 'center', alignItems: 'center'}}>
                    <div  style={{width: "90%", flexDirection: "column", display: 'flex', marginTop: 10, backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
                        <a style={{textAlign: 'center', fontSize: 20, textJustify: 'center', marginTop: 10, marginBottom: 10, color: "white", fontWeight: '400', fontFamily: 'Rubik'}}>SIGN INTO SUPER ANIMAL ACCOUNT TO LINK</a>
                    </div> 
                    <div className="input field" style={{marginleft:20, backgroundColor: 'transparent', width: '90%'}}>
                        <label htmlFor="email" style={{textAlign: 'center', fontSize: 20, textJustify: 'center', display: 'flex', marginLeft:20, marginRight: 'auto', marginTop: 10, color: "white", fontWeight: '300', fontFamily: 'Rubik'}}>Email</label>
                        <input type="email" id="email" ref={this.emailField} style={{ fontSize: 20, textJustify: 'center', color: "white", fontWeight: '300', fontFamily: 'Rubik', backgroundColor: '#56b584', borderRadius: 200,paddingLeft: 20}}/>
                    </div>
                    <div className="input field" style={{marginleft:20, backgroundColor: 'transparent', width: '90%'}} >
                        <label htmlFor="password" style={{textAlign: 'center', fontSize: 20, textJustify: 'center', display: 'flex', marginLeft:20, marginRight: 'auto', marginTop: 0, color: "white", fontWeight: '300', fontFamily: 'Rubik'}}>Password</label>
                        <input type="password" id="password" ref={this.passField} style={{fontSize: 20, textJustify: 'center', color: "white", fontWeight: '300', fontFamily: 'Rubik', backgroundColor: '#56b584', borderRadius: 200,paddingLeft: 20}}/>
                    </div>
                    <div className="red-text center">
                            {authError ? <p>{authError}</p> : null}
                    </div>       
                    <div className="input-field" style={{display: 'flex', flexDirection: "column", marginTop: 25, marginBottom: 25, backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
                        <button style={{ padding : 25, minWidth : 160, maxWidth: "100%" ,color: "#55b690", fontSize: 20, borderRadius:200, backgroundColor: 'white', fontWeight: '400', fontFamily: 'Rubik', borderWidth: 0}} onClick={this.playfabLogin}>Login</button>
                    </div>  
                </div>

                <div style = {{ backgroundColor: 'transparent',  height: 40, width: 100}}>
                </div>


            </div>
          )}
        else
        {
            //Se tiene que conectar
            if(twitchID == "" ){
                return (
                    
                    <div  style={{width: "100%", backgroundColor: 'transprent', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                        <div style = {{display: 'flex', flexDirection: 'column' , justifyContent: 'center', alignItems: 'center', backgroundColor: 'transprent', width: '70%'}}>
                            <img style={{ backgroundColor: 'transprent', marginTop:10,  height: '40%', width: '40%', maxHeight:300, maxWidth: 510}} src={require("./img/sar-logo.png")}/>
                        </div>
                        <div  style={{width: "90%",  display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
                            <a style={{textAlign: 'center', fontSize: 28, textJustify: 'center', marginBottom: 0, color: "white", fontWeight: '500', fontFamily: 'Rubik'}}>TWITCH DROPS</a>
                        </div>
                        <div  style={{width: "90%",  display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
                            <a style={{textAlign: 'center', fontSize: 20, textJustify: 'center', marginBottom: 20, color: "white", fontWeight: '300', fontFamily: 'Rubik'}}>Watch streams to earn in-game rewards</a>
                        </div>                        
                        <div  >
                            {pfStatus == 0 &&
                            <a>
                                <button style={{ padding : 25, minWidth : 300, maxWidth: "100%" ,color: "white", fontSize: 20, borderRadius:200, backgroundColor: 'transprent', fontWeight: '400', fontFamily: 'Rubik', borderWidth: 0}} >Checking Account</button>
                            </a>
                            }
                            {pfStatus == 1 &&
                            <a >
                                <button style={{ padding : 25, minWidth : 300, maxWidth: "100%" ,color: "white", fontSize: 20, borderRadius:200, backgroundColor: 'transprent', fontWeight: '400', fontFamily: 'Rubik', borderWidth: 0}} >Account already connected</button>
                            </a>
                            }
                            {pfStatus == 2 &&
                            <a href={GETAUTHTWITCH}>
                                <button style={{ padding : 25, minWidth : 300, maxWidth: "100%" ,color: "white", fontSize: 20, borderRadius:200, backgroundColor: '#8205b4', fontWeight: '400', fontFamily: 'Rubik', borderWidth: 0}} >Sign in with Twitch</button>
                            </a>
                            }
                        </div>
                        <div  style={{width: "60%", display : 'flex', flexDirection: "column", marginTop: 20, marginLeft: 'auto', marginRight:'auto', backgroundColor: 'transprent', justifyContent: 'center', alignItems: 'center'}}>
                            <button style={{padding : 25, minWidth : 300, maxWidth: "100%" ,color: "white", fontSize: 20, borderRadius:200, backgroundColor: '#de5f35', fontWeight: '400', fontFamily: 'Rubik', borderWidth: 0}} onClick={()=>this.playfabLogout("")}>Log out of Animal Account</button>
                        </div>                                                
                    </div>
                )}
            else
            {
                return(
                <div style={{width: "100%", backgroundColor: 'transprent', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>   
                    <div style = {{display: 'flex', flexDirection: 'column' , justifyContent: 'center', alignItems: 'center', backgroundColor: 'transprent', width: '70%'}}>
                        <img style={{ backgroundColor: 'transprent', marginTop:10,  height: '40%', width: '40%', maxHeight:300, maxWidth: 510}} src={require("./img/sar-logo.png")}/>
                    </div>
                    <div  style={{width: "90%",  display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
                        <a style={{textAlign: 'center', fontSize: 28, textJustify: 'center', marginBottom: 0, color: "white", fontWeight: '500', fontFamily: 'Rubik'}}>TWITCH DROPS</a>
                    </div>
                    <div  style={{width: "90%",  display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', justifyContent: 'center', alignItems: 'center'}}>
                        <a style={{textAlign: 'center', fontSize: 20, textJustify: 'center', marginBottom: 20, color: "white", fontWeight: '300', fontFamily: 'Rubik'}}>Watch streams to earn in-game rewards</a>
                    </div> 
                    <div  style={{width: "75%", maxWidth: 700 ,display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius:10, backgroundColor: linkingMessage }}>
                        <a style={{textAlign: 'center', fontSize: 25, textJustify: 'center', color: "white", fontWeight: '500', fontFamily: 'Rubik', padding: 15}}>{message}</a>
                    </div>                    
                </div>
                )
            }
        }
    } 
}


const mapDispatchToProps = (dispatch) => {
    return {
        setPlayfabID: (id) => dispatch({ type: 'SET_PF_ID', id:id })
    }
}

const mapStateToProps = (state) => {
    return {
        playfabID:state.auth.playfabID
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
  )(Home)
